



















































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { listMixin } from "@/mixins/listMixin";
import { GetHuishouzhan, PostHuishouzhan } from "@/request/storehouse";
import MainHeader from "@/components/main-header/main-header.vue";
import NavLeft from "@/components/nav-left/nav-left.vue";
@Component({
  components: {
    MainHeader,
    NavLeft,
  },
})
export default class Name extends mixins(listMixin) {
  private patients: any = [];
  private huanyuan(item: any) {
    const params: any = {
      project_id: item.project_id,
      patient_id: item.patient_id,
      operate: "还原",
    };
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    PostHuishouzhan(this, params, loading).then((res: any) => {
      loading.close();
      this.$message.success("还原成功！");
      this.getList();
    });
  }
  getList() {
    const params: any = {
      params: {
        order_field: this.filters.sort_field,
        order_value: this.filters.sort_value,
        current_page: this.filters.current_page,
        page_count: this.filters.page_count,
        search: this.filters.search,
      },
    };
    localStorage.setItem("recycleFilter", JSON.stringify(this.filters));
    const loading = this.$loading({
      lock: true,
      text: "加载中……",
      spinner: "el-icon-loading",
      customClass: "loadClass",
      background: "rgba(255, 255, 255, 0.7)",
    });
    GetHuishouzhan(this, params, loading)
      .then((data: any) => {
        loading.close();
        this.patients = data.data;
        this.filters.total_page = data.total_page;
        this.filters.total_count = data.total_count;
        if (this.patients.length === 0) {
          this.ifShowEmpty = true;
        } else {
          this.ifShowEmpty = false;
        }
      })
      .catch(() => {
        loading.close();
      });
  }
  /**
   * @description 初始化
   */
  mounted() {
    const d = localStorage.getItem("recycleFilter") || "";
    if (d) {
      this.filters = JSON.parse(d);
    }
    this.getList();
  }
}
